@import url("https://use.typekit.net/kkb2dih.css");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.jumbotron {
  // should be relative path of the entry scss file
  background-image: url("../../vendors/images/sample.jpg");
  background-size: cover;
}

.btn-green {
  @apply bg-button-green hover:bg-green-700 text-white font-bold mb-6 py-2 px-14 rounded-full;
}

.btn-blue {
  @apply inline-flex items-center px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md;
  @apply hover:bg-blue-700;
  @apply focus:outline-none;
  @apply focus:ring-2;
  @apply focus:ring-blue-400;
  @apply focus:ring-opacity-75;
}

.text-input-field {
  @apply placeholder:text-text-grey placeholder:text-lg block placeholder:font-futura font-normal;
  @apply bg-white w-full border text-lg border-slate-300 rounded-full py-2 font-futura font-normal;
  @apply pl-9 pr-3 shadow-sm focus:outline-none focus:border-slate-500 focus:ring-slate-500 focus:ring-1;
}

.report-form-choice-field {
  @apply border-none bg-safilo-grey font-futura font-medium text-lg text-[#606060] py-2 px-4;
}

.report-form-number-field {
  @apply border-none bg-safilo-grey font-futura font-medium text-lg text-[#606060] py-2 px-4;
}

.scratch-panel-container {
  @apply grid grid-cols-3 gap-4 py-6 justify-items-start;
  @apply place-content-center md:justify-items-center md:gap-x-10 md:gap-y-6;
}

.panel {
  @apply flex justify-start col-span-1 h-24 w-24 relative md:justify-center;
}

.scratch-panel {
  @apply absolute top-0 left-0 rounded-md;
}

.cover {
  @apply z-10;
}

.scratched {
  @apply border-4 border-safilo-purple;
}

.modal {
  @apply bg-white p-4 m-4 items-center justify-center rounded-sm;
}
